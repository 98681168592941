import config from "@authentication/AwsConfig";
import { containsAction } from "@helpers/Utils";
import useGumsService from "@hooks/useGumsService";
import { useTracking } from "@hooks/useTracking";
import { AdminTypes } from "@type";
import { Amplify } from "aws-amplify";
import { confirmSignIn, fetchAuthSession, fetchMFAPreference, fetchUserAttributes, getCurrentUser, signIn, signOut } from "aws-amplify/auth";
import { createContext, useCallback, useEffect, useRef, useState } from "react";
import { Agent } from "./Agents";

Amplify.configure(config)

type GumRole = {
  actions: Array<string>;
  resource: {
    teannt_id: number
  }
}

type AppRole = GumRole & {
  resource: {
    name: string
  }
}

type CustomACL = {
  app: Array<AppRole>
  gums: Array<GumRole>
}

export type AuthContext = {
  authenticate: (email: string, password: string) => Promise<any>,
  loadUserData: () => Promise<any | null>,
  completeTotp: (code: string) => Promise<any>,
  logout: () => Promise<any>,
  getErrorTranslationCode: (error: any) => string,
  refreshAccessToken: () => Promise<any>,
  hasUpliftAdminRole: () => boolean,
  agentEmail: string | null,
  agentId: string | null,
  agentACL: CustomACL | null,
  currentUser: Agent | null,
  selfAdminType: AdminTypes,
  mfaDisabled: boolean | undefined,
  isMfaRegistered: boolean | undefined,
};

const COGNITO_ERRORS_MAP: any = {
  NotAuthorizedException: [
    {
      msg: "Password attempts exceeded",
      key: "attempts"
    },
    {
      msg: "User password cannot be reset in the current state.",
      key: "cannot-reset"
    },
    {
      msg: "Temporary password has expired and must be reset by an administrator.",
      key: "pw-expired"
    },
  ],
  UserNotFoundException: "not-found",
  UserNotConfirmedException: "unconfirmed",
  LimitExceededException: "too-many",
  ExpiredCodeException: "expired",
  CodeMismatchException: "mismatch",
}

const AuthenticationContext = createContext({} as AuthContext);

const Authentication = (props: any) => {
  const { trackAuth, trackAuthResult, trackAxios } = useTracking();
  const gumsApi = useGumsService();
  const [currentUser, setCurrentUser] = useState<Agent | null>(null);
  const [agentEmail, setAgentEmail] = useState<string | null>(null);
  const [agentId, setAgentId] = useState<string | null>(null);
  const [agentACL, setAgentACL] = useState<CustomACL | null>(null);
  const [selfAdminType, setSelfAdminType] = useState(AdminTypes.noAdmin);
  const [mfaDisabled, setMfaDisabled] = useState<boolean | undefined>(undefined);
  const [isMfaRegistered, setMfaRegistered] = useState<boolean | undefined>(undefined);

  const loadUserDataPromise = useRef<Promise<any> | null>(null)
  const refreshAccessTokenPromise = useRef<Promise<any> | null>(null)

  const loadUserData = useCallback(() => {
    if (loadUserDataPromise.current) return loadUserDataPromise.current;

    loadUserDataPromise.current = new Promise(async (resolve, reject) => {

      let isAuthenticated = false
      Promise.all([

         getCurrentUser().then(currentUser => {
          setAgentId(currentUser.username)
        }),

        fetchUserAttributes().then(userAttrs => {
          setAgentEmail(userAttrs.email || "")
          setMfaDisabled(userAttrs["custom:mfa_disabled"] === "1")
        }),

        fetchMFAPreference().then(preferences => {
          setMfaRegistered(!!preferences.enabled)
        }),

        fetchAuthSession().then(session => {
          if (!session.tokens) return reject();

          isAuthenticated = true
          setAgentACL((prev) => {
            if (prev !== null) return prev;
            return JSON.parse(session.tokens?.idToken?.payload["custom:acl"] as string);
          });
        })

      ])
        .then(() => resolve(isAuthenticated))
        .catch(err => reject(err))
        .finally(() => loadUserDataPromise.current = null)
    })

    return loadUserDataPromise.current
  }, []);

  const refreshAccessToken = useCallback(() => {
    if (refreshAccessTokenPromise.current) return refreshAccessTokenPromise.current;

    refreshAccessTokenPromise.current = new Promise(async (resolve, reject) => {
      fetchAuthSession({ forceRefresh: true }).then(response => {
        resolve(response)
      }).catch(err => {
        if (err
          && (err?.name?.includes("NotAuthorizedException")
            || err?.name?.includes("PasswordResetRequiredException"))) {
              window.location.reload()
        }
      })
    }).finally(() => refreshAccessTokenPromise.current = null)

    return refreshAccessTokenPromise.current;

  }, []);

  const authenticate = useCallback((email: string, password: string) => {
    return new Promise(async (resolve, reject) => {
      if (!trackAuth || !trackAuthResult) return reject();

      const Username = email.toLocaleLowerCase();

      trackAuth('sign-in', '');

      const ipResponse = await gumsApi.getIp();

      signIn({
        username: Username,
        password: password,
        options: {
          authFlowType: "CUSTOM_WITH_SRP",
          clientMetadata: {
            requestId: ipResponse?.data?.request_id,
            sourceIp: ipResponse?.data?.ip,
            source: "standalone"
          }
        }
      }).then(signInRsp => {
        if (signInRsp.isSignedIn && signInRsp.nextStep.signInStep === "DONE") {
          trackAuthResult("sign-in", "success");
          resolve({success: true});
        } else if (signInRsp.nextStep.signInStep === "RESET_PASSWORD") {
          trackAuthResult("sign-in", "reset-pw");
          resolve({ resetPassword: true });
        } else if (signInRsp.nextStep.signInStep === "CONFIRM_SIGN_IN_WITH_NEW_PASSWORD_REQUIRED") {
          trackAuthResult("sign-in", "reset-pw");
          resolve({ newPasswordRequired: true });
        } else if (signInRsp.nextStep.signInStep === "CONFIRM_SIGN_IN_WITH_TOTP_CODE") {
          trackAuthResult("sign-in", "mfa-required");
          resolve({ totpRequired: true })
        } else if (signInRsp.nextStep.signInStep === "CONFIRM_SIGN_IN_WITH_CUSTOM_CHALLENGE") {
          trackAuthResult("sign-in", "custom-challenge");
          resolve({ customChallenge: true, params: signInRsp.nextStep.additionalInfo })
        } else {
          trackAuthResult("sign-in", "fail");
          reject({ code: `Invalid sign in next step: ${signInRsp.nextStep.signInStep}` });
        }
      }).catch(err => {
        trackAuthResult("sign-in", "fail");
        reject(err);
      })
    });
  }, [trackAuthResult, trackAuth, gumsApi]);

  const logout = useCallback(async () => {
    return new Promise(async (resolve, reject) => {
      if (!trackAxios) return reject();

      trackAxios("sign-out", "POST")
      signOut().then(() => window.location.reload())
        .catch((err) => reject(err))
    })
  }, [trackAxios]);

  const completeTotp = useCallback((code: string) => {
    return new Promise(async (resolve, reject) => {
      trackAuth('complete-totp', '')
      confirmSignIn({ challengeResponse: code })
        .then((response) => {
          if (response.isSignedIn)
            resolve({});
          else if (response.nextStep.signInStep === "CONFIRM_SIGN_IN_WITH_CUSTOM_CHALLENGE")
            resolve({ customChallenge: true, params: response.nextStep.additionalInfo });
        })
        .catch(() => reject())
    })
  }, [trackAuth]);

  const getErrorTranslationCode = useCallback((error: any) => {
    const errorCode = COGNITO_ERRORS_MAP[error.code];

    if (!errorCode) return "catch-all";
    if (typeof errorCode === 'string') return errorCode;

    const subCode = errorCode.find((code: any) => {
      return error.message.includes(code.msg);
    });

    if (!subCode) return 'invalid';

    return subCode.key;
  }, []);

  useEffect(() => {
    if (!agentACL || !agentACL.gums || agentACL.gums.length === 0) return;

    const upliftGumRoles = agentACL.gums
      .filter((role: any) => role.resource.tenant_id.toString() === process.env.REACT_APP_UPLIFT_TENANT_ID)

    const somePartners = agentACL.gums.length > 1;
    const superAdmin = containsAction(upliftGumRoles, "**");
    const csAdmin = containsAction(agentACL.gums, "power");
    const upliftAdmin = upliftGumRoles.some((role: any) => {
      return ['write', 'read'].every(x => role.actions.includes(x));
    });

    if (superAdmin && upliftAdmin) setSelfAdminType(AdminTypes.omniAdmin);
    else if (superAdmin) setSelfAdminType(AdminTypes.superAdmin);
    else if (csAdmin) setSelfAdminType(AdminTypes.csAdmin);
    else if (somePartners) setSelfAdminType(AdminTypes.multiAdmin);
    else setSelfAdminType(AdminTypes.singleAdmin);

  }, [setSelfAdminType, agentACL])

  useEffect(() => {
    if (!gumsApi) return
    if (!agentEmail) return
    if (!agentACL) return
    if (loadUserDataPromise == null) return

      gumsApi.fetchCurrentUser().then((rsp: any) => { setCurrentUser(rsp.data) })
  }, [gumsApi, agentEmail, agentACL, loadUserDataPromise])

  const hasUpliftAdminRole = useCallback(() => {
    if (!agentACL) return false;

    const upliftGumRoles = agentACL.gums
                                   .filter(
                                    (role: any) => role.resource.tenant_id.toString() === process.env.REACT_APP_UPLIFT_TENANT_ID
                                   );

    return upliftGumRoles.some((role: any) => {
        return ['write', 'read'].every(x => role.actions.includes(x));
    });
  }, [agentACL]);

  return (
    <AuthenticationContext.Provider value={{
      currentUser, agentEmail, loadUserData, refreshAccessToken, authenticate, logout, completeTotp, getErrorTranslationCode,
      agentId, agentACL, selfAdminType, isMfaRegistered, mfaDisabled, hasUpliftAdminRole
    }}>
      {props.children}
    </AuthenticationContext.Provider>
  );
};

export { Authentication, AuthenticationContext };
