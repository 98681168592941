import validator from 'validator';

export const PASSWORD_VALIDATIONS_REGEX = [
    // Check that it has a number
    /[\d]/,
    // Check that it has an uppercase letter
    /[A-Z]/,
    // Check that it has an lowercase letter
    /[a-z]/,
    // Check that it does not has a character repeated more than three times
    /(.)\1{2,}/
]

export function isValidName(name: string, messages: any) {
    if (!name) return [false, messages['required']];

    if (name.length < 2) return [false, messages['invalid']];
    if (!/^[a-zA-Z\s\-\p{L}\p{M}]+$/gu.test(name)) return [false, messages['invalid']];

    return [true];
}

export function isValidEmail(email: string, messages: any) {
    if (!email || validator.isEmpty(email)) return [false, messages['required']];

    if (!validator.isEmail(email)) return [false, messages['invalid']];

    return [true];
}

export function isValidEmailDomain(email: string, messages: any) {
    if (!email || validator.isEmpty(email)) return [false, messages['required']];

    if (email.indexOf('@') === -1 || email.indexOf('@') >= 1)  return [false, messages['invalid']];

    return [true];
}

export function isValidPass(pass: string, messages: any) {
    if (!pass || validator.isEmpty(pass)) return [false, messages['required']];

    if (pass.length < 8) return [false, messages['invalid']];

    return [true];
}

/*
This are the rules for a valid password

 * Minimum of 8 characters
 * At least 1 uppercase letter
 * At least 1 lowercase letter
 * At least 1 number
 * Cannot contain 3 consecutive instances of the same character
*/

export function isValidNewPass(pass: string, messages: any) {
    if (!pass || validator.isEmpty(pass)) return [false, messages['required']];

    const [
        hasNumber,
        hasUpperCase,
        hasLowerCase,
        hasRepeated
    ] = PASSWORD_VALIDATIONS_REGEX;

    if (pass.length < 8) return [false, messages['invalid']];
    if (!hasNumber.exec(pass)) return [false, messages['invalid-pass-number']];
    if (!hasUpperCase.exec(pass)) return [false, messages['invalid-pass-uppercase']];
    if (!hasLowerCase.exec(pass)) return [false, messages['invalid-pass-lowercase']];
    if (hasRepeated.exec(pass)) return [false, messages['invalid-pass-repeated']];

    return [true];
}

export function isValidConfirmationPass(confirmPass: string, messages: any, pass: string) {
    if (!confirmPass || validator.isEmpty(confirmPass)) return [false, messages['required']];

    if (confirmPass !== pass) return [false, messages['invalid']];

    return [true];
}

export function isValidPhone(phone: string, messages: any) {
    if (!phone || validator.isEmpty(phone)) return [false, messages['required']];
    if (phone.replace(/[_]*/g, '').length < 10) return [false, messages['invalid']];
    if (!validator.isMobilePhone(phone)) return [false, messages['invalid']];

    return [true];
}

export function isValidBirthDate(birthdate: Date | string, messages: any) {
    if (!birthdate || validator.isEmpty(birthdate)) return [false, messages['required']];

    const date: Date = typeof birthdate === 'string' ? new Date(birthdate) : birthdate;
    if (date.toString() === 'invalid date') return [false, messages['required']];

    // const diff = today.getTime() - date.getTime();
    // const year = 31536000000;

    // if (diff > year * 125) return [false, messages['too-old']];
    // if (diff < year * 18) return [false, messages['underage']];

    return [true];
}

export function isValidDepartureDate(date: Date | string, windowDays: number, messages: any) {
    if (!date) return [false, messages['required']];

    const today = new Date();
    const safeDate: Date = typeof date === 'string' ? new Date(date) : date;
    const dayInMs = 1000 * 60 * 60 * 24;
    const year = 31536000000;
    
    if (Math.ceil((safeDate.getTime() - today.getTime()) / dayInMs) < windowDays) return [false, messages['early']];
    if (Math.abs(safeDate.getTime() - today.getTime()) >= year * 3) return [false, messages['too-far']];
       
    return [true];
}

export function isValidArrivalDate(date: Date | string, secondDate: Date | string, messages: any) {
    if (!date) return [false, messages['required']];

    const safeDate: Date = typeof date === 'string' ? new Date(date) : date;
    const safeSecondDate: Date | null = typeof secondDate === 'string' ? new Date(secondDate) : secondDate;
    
    return safeSecondDate < safeDate ? [true]: [false, messages['early']];
}

export function isValuePresent(value: string, messages: any) {
    if (!value) return [false, messages['required']];

    return [true];
}

export function isValidStatus(value: string, current: string, messages: any) {
    if (!value) return [false, messages['required']];
    if (value === current) return [false, messages['required']];

    return [true];
}

export function isValidUPC(value: string, messages: any) {
    const regexp = new RegExp("UP-[0-9]{8}-[0-9]{1,2}");

    if (!value) return [false, messages['required']];

    if (!value.match(regexp)) return [false, messages['invalid']];

    return [true];
}

export function isTrueCondition(condition: boolean | Function, messages: any) {
    if (typeof condition === 'boolean') {
        if (!condition) return [false, messages['required']];

    }

    if (typeof condition === 'function') {
        if (!condition()) return [false, messages['required']];
    }

    return [true];
}
