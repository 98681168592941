import { Agent } from '@contexts/Agents';
import { Partner } from '@contexts/Partners';
import { cleanPhoneNumber } from '@helpers/Utils';
import { useSafeAxios } from '@hooks/useAxios';
import axios from 'axios';
import { useEffect, useState } from 'react';

export default function useGumsService() {
    const { makeAxiosCall } = useSafeAxios();
    const [api] = useState(() => axios.create({
        baseURL: process.env.REACT_APP_GUMS_API_BASE
    }));
    const [gumService, setGumsService] = useState<any>(null);

    useEffect(() => {
        if (!api) return;
        if (!makeAxiosCall) return;

        setGumsService({
            fetchAppPermissions() {
                return makeAxiosCall({
                    url: '/app/permissions-meta',
                    method: 'GET'
                }, api, 'fetch-app-permissions-meta');
            },
            fetchUserRoles() {
                return makeAxiosCall({
                    url: '/app/role',
                    method: 'GET'
                }, api, 'fetch-app-roles');
            },
            fetchGumsPermissions() {
                return makeAxiosCall({
                    url: '/gums/permissions-meta',
                    method: 'GET'
                }, api, 'fetch-gums-permissions-meta');
            },
            fetchGumsRoles() {
                return makeAxiosCall({
                    url: '/gums/role',
                    method: 'GET'
                }, api, 'fetch-gums-roles');
            },
            fetchPartners() {
                return makeAxiosCall({
                    url: '/tenants',
                    method: 'GET'
                }, api, 'fetch-all-tenants');
            },
            editPartner(tenantId: number, partnerName: string) {
                return makeAxiosCall({
                    url: `/tenants/${tenantId}`,
                    method: 'PUT',
                    data: {
                        name: partnerName,
                    }
                }, api, 'edit-tenant-info');
            },
            addPartnerDomain(tenant_id: number, domain: string) {
                return makeAxiosCall({
                    url: `/tenants/${tenant_id}/domains/add`,
                    method: 'POST',
                    data: {
                        domain
                    }
                }, api, 'add-tenant-domain');
            },
            removePartnerDomain(tenant_id: number, domain: string, ) {
                return makeAxiosCall({
                    url: `/tenants/${tenant_id}/domains/remove`,
                    method: 'POST',
                    data: {
                        domain,
                    }
                }, api, 'remove-tenant-domain');
            },
            editGumRole(roleId: number, name: string, tenantId: number) {
                return makeAxiosCall({
                    url: `/gums/roles/${roleId}`,
                    method: 'PUT',
                    data: {
                        name,
                        tenant_id: tenantId
                    }
                }, api, 'edit-tenant-gums-role');
            },
            editAppRole(roleId: number, name: string, tenantId: number) {
                return makeAxiosCall({
                    url: `/app/roles/${roleId}`,
                    method: 'PUT',
                    data: {
                        name,
                        tenant_id: tenantId
                    }
                }, api, 'edit-tenant-app-role');
            },
            editAppResourceRole(roleId: number, upc: string, tenantId: number) {
                return makeAxiosCall({
                    url: `/tenants/${tenantId}/app/resource/${roleId}`,
                    method: 'PUT',
                    data: {
                        name: upc
                    }
                }, api, 'edit-app-resource');
            },
            createResource(upc: string, tenantId: number) {
                return makeAxiosCall({
                    url: `/tenants/${tenantId}/app/resource`,
                    method: 'POST',
                    data: {
                        name: upc
                    }
                }, api, 'create-app-resource');
            },
            createRole(name: string, tenantId: number, gumRole = false) {
                return makeAxiosCall({
                    url: `/tenants/${tenantId}/${gumRole ? 'gums' : 'app'}/roles`,
                    method: 'POST',
                    data: {
                        name: name
                    }
                }, api, `create-${gumRole ? 'gums' : 'app'}-role`);
            },
            createPermission(resourceId: number, actionIds: Array<number>, gumRole = false) {
                return makeAxiosCall({
                    url: `/${gumRole ? 'gums' : 'app'}/permissions`,
                    method: 'POST',
                    data: {
                        action_ids: actionIds,//[2],
                        resource_id: resourceId
                    }
                }, api, `create-${gumRole ? 'gums' : 'app'}-permission`);
            },
            assignPermission(tenantId: number, roleId: number, permissionIds: Array<number>, gumRole = false) {
                return makeAxiosCall({
                    url: `/tenants/${tenantId}/${gumRole ? 'gums' : 'app'}/roles/${roleId}/permissions`,
                    method: 'POST',
                    data: {
                        permission_ids: permissionIds
                    }
                }, api, `add-permission-to-${gumRole ? 'gums' : 'app'}-role`);
            },
            createPartner(name: string, version: string) {
                return makeAxiosCall({
                    url: `/tenants`,
                    method: 'POST',
                    data: {
                        name, version
                    }
                }, api, 'create-partner');
            },
            fetchPartner(tenantId: number) {
                return makeAxiosCall({
                    url: `/tenants/${tenantId}`,
                    method: 'GET'
                }, api, 'fetch-single-tenant');
            },
            fetchPartnerRoles(tenantId: number, type: 'app' | 'gums') {
                return makeAxiosCall({
                    url: `/tenants/${tenantId}/${type}/roles`,
                    method: 'GET'
                }, api, `fetch-${type}-roles`);
            },
            // User endpoints
            fetchAgents(page = 1, filters= '', sort = '', tenantId: null | number = null, me = null) {
                return makeAxiosCall({
                    url: `${ tenantId ? '/tenants/' + tenantId: '' }/users?page=${page}${sort ? '&sort='+sort : ''}${filters}`,
                    method: 'GET',
                }, api, 'fetch-users');
            },
            createAgent(agent: Agent) {
                return makeAxiosCall({
                    url: `/users`,
                    method: 'POST',
                    data: {
                        "custom:version": agent['custom:version'],
                        email: agent.email,
                        family_name: agent.family_name,
                        name: agent.name,
                        phone_number: '+1' + cleanPhoneNumber(agent.phone_number),
                        ...(agent['custom:mfa_disabled'] ? {"custom:mfa_disabled": agent['custom:mfa_disabled']} : {}),
                    }
                }, api, 'create-user');
                // return new Promise((resolve, reject) => {
                //     setTimeout(() => {
                //         const a = 1

                //         if (a) resolve({ data: { id: Math.ceil(Math.random() * 1000)}});
                //         else reject({});
                //     }, 2000);
                // })
            },
            assignAgentToTenant(agentId: number, tenantId: number) {
                return makeAxiosCall({
                    url: `/tenants/${tenantId}/add-user/${agentId}`,
                    method: 'POST'
                }, api, 'add-user-to-tenant');
                // return new Promise((resolve, reject) => {
                //     setTimeout(() => {
                //         const a = Math.ceil(Math.random() * 10) % 2

                //         if (a) resolve({});
                //         else reject({});
                //     }, 2000);
                // })
            },
            unassignAgentToTenant(agentId: number, tenantId: number) {
                return makeAxiosCall({
                    url: `/tenants/${tenantId}/remove-user/${agentId}`,
                    method: 'POST'
                }, api, 'add-user-to-tenant');
                // return new Promise((resolve, reject) => {
                //     setTimeout(() => {
                //         const a = Math.ceil(Math.random() * 10) % 2

                //         if (a) resolve({});
                //         else reject({});
                //     }, 2000);
                // })
            },
            assignRolesToAgent(agentId: number, rolesId: Array<number>, roleType = 'app', tenantId = null) {
                return makeAxiosCall({
                    url: tenantId ? `/tenants/${tenantId}/users/${agentId}/${roleType}/add-roles` : `/users/${agentId}/${roleType}/add-roles`,
                    method: 'POST',
                    data: {
                        role_ids: rolesId
                    }
                }, api, `add-${roleType}-role-to-user`);
                // return new Promise((resolve, reject) => {
                //     setTimeout(() => {
                //         const a = Math.ceil(Math.random() * 10) % 2

                //         if (a) resolve({});
                //         else reject({});
                //     }, 2000);
                // })
            },
            editAgent(agentId: number, agent: Agent, tenantId?: number) {
                return makeAxiosCall({
                    url: tenantId ? `/tenants/${tenantId}/users/${agentId}` : `/users/${agentId}`,
                    method: 'PUT',
                    data: {
                        "custom:version": agent['custom:version'],
                        email: agent.email,
                        family_name: agent.family_name,
                        name: agent.name,
                        phone_number:  '+1' + cleanPhoneNumber(agent.phone_number),
                        locked_reporting_cruise_line: agent.locked_reporting_cruise_line,
                        locked_reporting_ship_code: agent.locked_reporting_ship_code,
                        ...(tenantId ? {} : {"custom:mfa_disabled": agent['custom:mfa_disabled']}),
                    }
                }, api, 'edit-user-info');
            },
            toggleAgent(agentId: number, action: string, reason: string, tenantId?: number) {
                return makeAxiosCall({
                    url: tenantId ? `/tenants/${tenantId}/users/${agentId}/${action}` : `/users/${agentId}/${action}`,
                    method: 'POST',
                    data: {
                        "description": reason
                    }
                }, api, `${action}-user`);
            },
            forceResetPassword({ agentId, tenantId } : { agentId: number, tenantId?: number }) {
                return makeAxiosCall({
                    url: `${tenantId ? '/tenants/' + tenantId : ''}/users/${agentId}/reset-pw`,
                    method: 'POST'
                }, api, 'reset-pw-user');
            },
            resendInvitationEmail({ agent }: { agent: Agent}) {
                return makeAxiosCall({
                    url: `/users-resend`,
                    method: 'POST',
                    data: {
                        "custom:version": agent['custom:version'],
                        username: agent.username,
                        email: agent.email,
                        family_name: agent.family_name,
                        name: agent.name,
                        phone_number:  '+1' + cleanPhoneNumber(agent.phone_number)
                    }
                }, api, 'resend-user');
            },
            unassignRolesToAgent(agentId: number, rolesId: Array<number>, roleType = 'app', tenantId = null) {
                return makeAxiosCall({
                    url: tenantId ? `/tenants/${tenantId}/users/${agentId}/${roleType}/remove-roles` : `/users/${agentId}/${roleType}/remove-roles`,
                    method: 'POST',
                    data: {
                        role_ids: rolesId
                    }
                }, api, `remove-${roleType}-role-from-user`);
                // return new Promise((resolve, reject) => {
                //     setTimeout(() => {
                //         const a = Math.ceil(Math.random() * 10) % 2

                //         if (a) resolve({});
                //         else reject({});
                //     }, 2000);
                // })
            },
            saveBulkLoad(rolesId: Array<number>, partner: Partner, agents: Array<Agent>) {
                return makeAxiosCall({
                    url: '/batch-create-users',
                    method: 'POST',
                    data: {
                        app_role_ids: rolesId,
                        tenant_id: partner.id,
                        users: agents.map(agent => ({
                            name: agent.name,
                            family_name: agent.family_name,
                            email: agent.email,
                            phone_number:  '+1' +cleanPhoneNumber(agent.phone_number),
                            "custom:version": partner.version,
                            ...(agent['custom:mfa_disabled'] !== undefined && {"custom:mfa_disabled": agent['custom:mfa_disabled']})
                        }))
                    }
                }, api, 'batch-create-users');
                // return new Promise((resolve, reject) => {
                //     setTimeout(() => {
                //         const a = true// Math.ceil(Math.random() * 10) % 2

                //         if (a) resolve({});
                //         else reject({});
                //     }, 2000);
                // })
            },
            getIp() {
                return makeAxiosCall({
                    url: '/api/get_agent_ip',
                    method: 'POST',
                    data: {}
                }, api, 'agent-get-ip');
            },
            fetchEvents(agentId: number, page = 1) {
                return makeAxiosCall({
                    url: `/users/${agentId}/events?page=${page}`,
                    method: 'GET'
                }, api, 'fetch-user-events');
            },
            fetchCurrentUser() {
                return makeAxiosCall({
                    url: '/users/self',
                    method: 'GET'
                }, api, 'fetch-current-user');
            },
        })
    }, [api, makeAxiosCall]);

    return gumService;
}
